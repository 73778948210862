<template>
  <n-icon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g
        fill="none"
        fill-rule="evenodd"
      >
        <circle
          stroke="#000"
          stroke-width="10"
          fill="#000"
          cx="256"
          cy="256"
          r="251"
        />
        <path
          d="M255.713 345.352 145.6 280.392 255.698 435.4 365.9 280.392l-110.232 64.96h.045ZM257.387 77 147.245 259.53l110.127 65.02L367.5 259.59 257.387 77Z"
          fill="#FFF"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  </n-icon>
</template>
