
import { h, ref } from 'vue'
import { Options, Vue } from 'vue-class-component'
import { ethList, vetList } from '../const'
import { Ref, VNodeChild } from '@vue/runtime-core'
import ConfirmDialog from './ConfirmDialog.vue'
import SwitchNetwork from './SwitchNetwork.vue'
import Address from './Address.vue'
import Eth from './icons/Eth.vue'
import Wvet from './icons/Wvet.vue'
import Balance from './Balance.vue'
import Vet from './icons/Vet.vue'
import Veth from './icons/Veth.vue'
import vechain from './icons/Vechain.vue'
import ethereum from './icons/Ethereum.vue'
import { BigNumber } from 'bignumber.js'
import { FormInst, FormItemRule } from 'naive-ui'

const { isAddress } = window.ethers
type TokenItem = typeof ethList[0]

@Options({
  data () {
    return {
      value: null,
      from: null,
      // formRef: ref<FormInst | null>(null),
      to: null,
      showRecipient: false,
      content: null,
      form: {
        amount: '',
        recipient: ''
      },
      rules: ref({
        amount: {
          validator: (rule: FormItemRule, value: string) => {
            const amount = new BigNumber(value)
            const temp = amount.multipliedBy('1e' + (this.from as TokenItem).decimals)
            if (!value || amount.isLessThanOrEqualTo(0)) {
              return new Error('Please input valid amount')
            } else if (
              amount.isNaN() ||
              !temp.isLessThanOrEqualTo(this.balance)
            ) {
              return new Error('Insufficient balance')
            } else if (!temp.isInteger() || temp.isNegative()) {
              return new Error('Invalid amount')
            } else {
              return true
            }
          }
        },
        recipient: {
          validator: (rule: FormItemRule, value: string) => {
            if (!isAddress(value)) {
              return new Error('Please input a valid address')
            } else {
              return true
            }
          }
        }
      })
    }
  },
  components: {
    Vet,
    Eth,
    Wvet,
    Veth,
    SwitchNetwork,
    Address,
    ConfirmDialog,
    vechain,
    ethereum,
    Balance
  },
  watch: {
    value (val: Ref<string>) {
      this.from = (this.fromList as TokenItem[]).find(item => {
        return item.label.includes(val.toString()) || val.toString().includes(item.label)
      })
      this.to = (this.toList as TokenItem[]).find(item => {
        return item.label.includes(val.toString()) || val.toString().includes(item.label)
      })
    },
    fromList (val: TokenItem[]) {
      this.value = val[0].value
    }
  },
  computed: {
    addresses (): Record<'ethereum' | 'vechain', string> {
      return this.$store.state.addresses
    },
    currentAddress (): string {
      return this.$store.getters.currentAddress
    },
    balance (): string {
      if (this.$store.state.balances && this.currentAddress) {
        return this.$store.state.balances[`${this.network}-${this.currentAddress.toLowerCase()}-${this.value.toLowerCase()}`]
      } else {
        return '0'
      }
    },
    network (): 'ethereum' | 'vechain' {
      return this.$store.state.network
    },
    fromList (): TokenItem[] {
      return this.network === 'ethereum' ? ethList : vetList
    },
    toList (): TokenItem[] {
      return this.network !== 'ethereum' ? ethList : vetList
    },
    toIcon (): string {
      return this.to.label.toLowerCase()
    },
    balances (): Record<string, string> | null {
      return this.$store.state.balances
    },
    ethColor () {
      return {
        color: '#0000001c',
        borderColor: '#000',
        textColor: '#000'
      }
    },
    vetColor () {
      return {
        color: '',
        borderColor: '#28008C',
        textColor: '#28008C'
      }
    }
  }
})
export default class Swap extends Vue {
  value!: Ref<string>
  from!: TokenItem
  to!: TokenItem
  fromList!: TokenItem[]
  toList!: TokenItem[]
  network!: string
  addresses!: Record<'ethereum' | 'vechain', string>
  showRecipient!: boolean
  currentAddress!: string
  balance!: string
  recipient!: string
  amount!: string
  showConfirm!: boolean
  content!: {
    wallet: string,
    token: string,
    network: string,
    symbol: string,
    amount: string
  }[]

  form!: {
    amount: string,
    recipient: string
  }

  created (): void {
    this.value = ref(this.fromList[0].value)
    this.from = this.fromList[0]
    this.to = this.toList.find(item => {
      return item.label.includes(this.from.label) || this.from.label.includes(item.label)
    })!
  }

  renderFun (option: TokenItem): VNodeChild {
    let icon = Eth
    switch (option.label) {
      case 'ETH':
        icon = Eth
        break
      case 'vETH':
        icon = Veth
        break
      case 'VET':
        icon = Vet
        break
      case 'wVET':
        icon = Wvet
        break
    }
    return h(
      'div',
      {
        style: {
          display: 'flex',
          alignItems: 'center'
        }
      },
      [
        h(
          icon,
          {
            size: 22,
            style: {
              marginLeft: '15px',
              marginRight: '10px'
            }
          }
        ),
        option.label
      ]
    )
  }

  renderSingleSelectTag (props: {
    option: TokenItem
    handleClose: () => void
  }): VNodeChild {
    return this.renderFun(props.option)
  }

  renderLabel (option: TokenItem): VNodeChild {
    return this.renderFun(option)
  }

  cll (): void {
    console.log('000')
  }

  async onSubmit (e: MouseEvent): Promise<void> {
    e.preventDefault();
    (this.$refs.formRef as FormInst).validate((e) => {
      if (!e) {
        this.content = [
          {
            wallet: this.currentAddress,
            network: this.from.network,
            amount: this.form.amount,
            symbol: this.from.label,
            token: this.from.address
          },
          {
            wallet: this.form.recipient,
            amount: this.form.amount,
            symbol: this.to.label,
            network: this.to.network,
            token: this.to.address
          }
        ];
        (this.$refs.confirm as ConfirmDialog).popUp().then(() => {
          this.form.amount = ''
          this.form.recipient = ''
        }).catch((error: Error) => {
          console.log('modal:', error)
        })
      }
    })
  }
}
