
import { Options, Vue } from 'vue-class-component'
import Metamask from './icons/Metamask.vue'
import Sync from './icons/Vechain.vue'

@Options({
  events: ['connected'],
  data () {
    return {
      connecting: false,
      current: '',
      hasMetamask: false,
      error: {
        vet: '',
        eth: ''
      }
    }
  },
  props: {
    network: String
  },
  components: {
    Metamask,
    Sync
  }
})
export default class Connect extends Vue {
  network!: 'ethereum' | 'vechain'
  connecting!: boolean
  current!: 'ethereum' | 'vechain'
  hasMetamask!: boolean
  error!: {
    vet: string
    eth: string
  }

  async connectVechain () {
    this.error.vet = ''
    this.connecting = true
    this.current = 'vechain'
    let timeoutId
    let _resolve!: (v: unknown) => void
    const raceTime = new Promise((resolve, reject) => {
      _resolve = resolve
      timeoutId = setTimeout(() => {
        reject(new Error('Connect timeout'))
      }, 20000)
    })
    try {
      const address = await Promise.race([this.$vet.connect(), raceTime])
      this.$store.commit('setAddress', { key: 'vechain', addr: address })
      this.$emit('connected')
    } catch (error: any) {
      this.error.vet = error.message
      console.log(error)
    } finally {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      if (_resolve) {
        _resolve(null)
      }
      this.connecting = false
    }
  }

  openMM () {
    window.open('https://metamask.io/download.html', '_blank')
  }

  created () {
    this.hasMetamask = this.$eth.isMetaMask()
  }

  async connectEth () {
    this.error.eth = ''
    this.connecting = true
    this.current = 'ethereum'
    let timeoutId
    let _resolve!: (v: unknown) => void
    const raceTime = new Promise((resolve, reject) => {
      _resolve = resolve
      timeoutId = setTimeout(() => {
        reject(new Error('Connect timeout'))
      }, 20000)
    })
    try {
      const address = await Promise.race([this.$eth.connect(), raceTime])
      this.$store.commit('setAddress', { key: 'ethereum', addr: address })
      this.$emit('connected')
    } catch (error: any) {
      if (error.code === -32002) {
        this.error.eth = 'Error connecting'
      } else if (error.message !== 'abort') {
        this.error.eth = error.message
        console.error(error)
      }
    } finally {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      if (_resolve) {
        _resolve(null)
      }
      this.connecting = false
    }
  }
}
