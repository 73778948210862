<template>
  <n-icon>
    <svg width="1024px" height="1024px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Artboard Copy 3</title>
      <defs>
        <linearGradient x1="2.79502486%" y1="50.0010958%" x2="102.795025%" y2="50.0010958%" id="linearGradient-1">
          <stop stop-color="#28008C" offset="13%"></stop>
          <stop stop-color="#00BED7" offset="60%"></stop>
          <stop stop-color="#82BE00" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="Artboard-Copy-3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="Rectangle" x="0" y="0" width="1024" height="1024"></rect>
        <g id="vechain-logo-" transform="translate(118.000000, 153.000000)" fill="url(#linearGradient-1)"
          fill-rule="nonzero">
          <path
            d="M788,0 L717.646509,0 C700.442253,0 684.763234,9.8721431 677.309669,25.3815337 L492.411508,410.384624 L492.206992,409.975498 L442.965841,512.524447 L443.154625,512.933573 L393.929206,615.451051 L147.880772,102.737776 L218.076943,102.737776 C235.2812,102.744062 250.960218,112.60992 258.413783,128.11931 L418.990277,460.738565 L468.262892,358.142409 L338.50538,89.6929584 C312.248839,34.8899875 256.887648,0.0136123416 196.130807,0 L0,0 L49.1624908,102.737776 L49.3198107,102.737776 L344.656591,718 L443.154625,718 L788,0 Z"
            id="Path"></path>
        </g>
      </g>
    </svg>
  </n-icon>
</template>
