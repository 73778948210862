
import { h, ref } from 'vue'
import { VNodeChild, Ref } from '@vue/runtime-core'
import { Vue, Options } from 'vue-class-component'
import { ethList, vetList } from '../const'
import Eth from './icons/Eth.vue'
import Wvet from './icons/Wvet.vue'
import Vet from './icons/Vet.vue'

import Veth from './icons/Veth.vue'
import { FormInst, FormItemRule, useMessage, MessageApi } from 'naive-ui'

type TokenItem = typeof ethList[0]
const { isAddress } = window.ethers
const chainIDS = {
  ethereum: process.env.VUE_APP_ETH_ID!,
  vechain: process.env.VUE_APP_VECHAIN_ID!
}

@Options({
  data () {
    return {
      msg: null,
      loading: false,
      token: '',
      form: {
        amount: '',
        receiver: ''
      },
      rules: {
        receiver: {
          validator: (rule: FormItemRule, value: string) => {
            if (!isAddress(value)) {
              return new Error('Please input a valid address')
            } else {
              return true
            }
          }
        }
      }
    }
  }
})
export default class Faucet extends Vue {
  token!: Ref<string>
  loading!: boolean
  msg!: MessageApi
  form!: {
    amount: string,
    receiver: string
  }

  get tokenList (): TokenItem[] {
    return [...ethList, ...vetList]
  }

  created (): void {
    this.msg = useMessage()
    this.token = ref(this.tokenList[0].value)
  }

  async onClaim (e: Event): Promise<void> {
    e.preventDefault()
    this.loading = true;
    (this.$refs.formRef as FormInst).validate(async (e) => {
      if (!e) {
        const receiver = this.form.receiver
        const item = this.tokenList.find(item => this.token.toString() === item.value)
        const chainname: 'ethereum' | 'vechain' = item?.network as unknown as 'ethereum' | 'vechain'
        const chainid = chainIDS[chainname]
        const token = item?.address

        const r = await fetch('/faucet', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            chainname,
            chainid,
            receiver,
            token
          })
        })
        const resp = await r.json()

        if (r.status === 200 && resp && resp.txid) {
          this.msg.success('Success')
        }
        this.loading = false
      }
    })
  }

  renderFun (option: TokenItem): VNodeChild {
    let icon = Eth
    switch (option.label) {
      case 'ETH':
        icon = Eth
        break
      case 'vETH':
        icon = Veth
        break
      case 'VET':
        icon = Vet
        break
      case 'wVET':
        icon = Wvet
        break
    }
    return h(
      'div',
      {
        style: {
          display: 'flex',
          alignItems: 'center'
        }
      },
      [
        h(
          icon,
          {
            size: 22,
            style: {
              marginLeft: '15px',
              marginRight: '10px'
            }
          }
        ),
        option.label
      ]
    )
  }

  renderSingleSelectTag (props: {
    option: TokenItem
    handleClose: () => void
  }): VNodeChild {
    return this.renderFun(props.option)
  }

  renderLabel (option: TokenItem): VNodeChild {
    return this.renderFun(option)
  }
}
