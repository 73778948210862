import {
  create,
  NConfigProvider,
  NButton,
  NLayout,
  NInput,
  NPopover,
  NLayoutHeader,
  NTabs,
  NLayoutContent,
  NDialogProvider,
  NMessageProvider,
  NModal,
  NIcon,
  NSpace,
  NSteps,
  NStep,
  NGrid,
  NCard,
  NGi,
  NList,
  NListItem,
  NThing,
  NTabPane,
  NAvatar,
  NPageHeader,
  NForm,
  NFormItem,
  NTag,
  NCollapse,
  NCollapseItem,
  NSelect,
  NEmpty,
  NButtonGroup
} from 'naive-ui'

const naive = create({
  components: [
    NConfigProvider,
    NButton,
    NLayout,
    NInput,
    NPopover,
    NLayoutHeader,
    NTabs,
    NSelect,
    NLayoutContent,
    NDialogProvider,
    NMessageProvider,
    NModal,
    NIcon,
    NSpace,
    NSteps,
    NStep,
    NGrid,
    NCard,
    NGi,
    NList,
    NListItem,
    NThing,
    NTabPane,
    NAvatar,
    NButtonGroup,
    NPageHeader,
    NForm,
    NFormItem,
    NTag,
    NCollapse,
    NEmpty,
    NCollapseItem
  ]
})

export default naive
