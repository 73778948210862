<template>
  <n-icon>
    <svg width="512px" height="512px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Artboard</title>
    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="wvet">
            <circle id="Oval" fill="#000000" fill-rule="nonzero" cx="256" cy="256" r="256"></circle>
            <path d="M90.253,96.777 C50.46,138.186 26,194.437 26,256.4 C26,318.774 50.786,375.36 91.045,416.843 M98.87,424.533 C140.07,463.152 195.474,486.8 256.4,486.8 C318.065,486.8 374.071,462.575 415.422,423.123 M422.717,415.846 C462.409,374.455 486.8,318.276 486.8,256.4 C486.8,195.144 462.895,139.471 423.904,98.202 M416.81,91.013 C375.33,50.773 318.758,26 256.4,26 C194.446,26 138.204,50.453 96.798,90.233" id="Shape" stroke="#999999" stroke-width="10"></path>
        </g>
        <g id="vechain-logo--copy-2" transform="translate(102.000000, 123.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <path d="M307,0 L279.590709,0 C272.888035,0 266.779585,3.84986082 263.875721,9.89809113 L191.840524,160.038572 L191.760846,159.879024 L172.576793,199.870258 L172.650342,200.029806 L153.472419,240.008766 L57.6134481,40.064871 L84.9614486,40.064871 C91.6641222,40.067322 97.7725724,43.9147318 100.676436,49.9629622 L163.23606,179.675207 L182.43237,139.665564 L131.879634,34.9777554 C121.650246,13.6061232 100.081863,0.00530843403 76.4113678,0 L0,0 L19.1534069,40.064871 L19.2146978,40.064871 L134.276109,280 L172.650342,280 L307,0 Z" id="Path"></path>
        </g>
    </g>
</svg>
  </n-icon>
</template>
