<template>
  <n-icon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g
        fill="none"
        fill-rule="evenodd"
      >
        <circle
          stroke="#427699"
          stroke-width="10"
          fill="#5DA7DA"
          cx="256"
          cy="256"
          r="251"
        />
        <path
          d="M90.253 96.777C50.46 138.186 26 194.437 26 256.4c0 62.374 24.786 118.96 65.045 160.443m7.825 7.69c41.2 38.619 96.604 62.267 157.53 62.267 61.665 0 117.671-24.225 159.022-63.677m7.295-7.277c39.692-41.391 64.083-97.57 64.083-159.446 0-61.256-23.905-116.929-62.896-158.198m-7.094-7.189C375.33 50.773 318.758 26 256.4 26c-61.954 0-118.196 24.453-159.602 64.233"
          stroke="#7BCFDA"
          stroke-width="10"
        />
        <path
          d="M255.713 345.352 145.6 280.392 255.698 435.4 365.9 280.392l-110.232 64.96h.045ZM257.387 77 147.245 259.53l110.127 65.02L367.5 259.59 257.387 77Z"
          fill="#FFF"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  </n-icon>
</template>
