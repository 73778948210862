import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Transfer = _resolveComponent("Transfer")!
  const _component_Receive = _resolveComponent("Receive")!
  const _component_n_collapse = _resolveComponent("n-collapse")!
  const _component_n_empty = _resolveComponent("n-empty")!

  return (_ctx.list.length)
    ? (_openBlock(), _createBlock(_component_n_collapse, {
        key: 0,
        "arrow-placement": "right",
        accordion: true
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (_ctx.network === item.from.chainName)
                ? (_openBlock(), _createBlock(_component_Transfer, {
                    key: item.bridgeTxId,
                    name: item.bridgeTxId,
                    item: item
                  }, null, 8, ["name", "item"]))
                : (_openBlock(), _createBlock(_component_Receive, {
                    onClaim: _ctx.$attrs.onClaim,
                    name: item.bridgeTxId,
                    item: item,
                    key: item.bridgeTxId
                  }, null, 8, ["onClaim", "name", "item"]))
            ], 64))
          }), 256))
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_n_empty, {
        key: 1,
        description: "No Transactions"
      }))
}