
import { Options, Vue } from 'vue-class-component'
import Metamask from './icons/Metamask.vue'
import Sync from './icons/Vechain.vue'

@Options({
  props: {
    network: String,
    text: String,
    dialog: Boolean
  },
  components: {
    Metamask,
    Sync
  }
})
export default class Disconnect extends Vue {
  network!: 'ethereum' | 'vechain'
  text!: string

  onDisconnect (): void {
    try {
      this.$store.commit('delBalance', { network: this.network, address: this.$store.getters.currentAddress })
      this.$store.commit('setAddress', { key: this.network, addr: null })
    } catch (error) {
      console.log(error)
    }
  }
}
