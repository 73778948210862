
import { h } from 'vue'
import { Options, Vue } from 'vue-class-component'
import * as config from '../config'
import Completed from './history/Completed.vue'
import Ongoing from './history/Ongoing.vue'
import { useDialog, DialogApi, useMessage, MessageApi, NButton } from 'naive-ui'
import Connect from './Connect.vue'
import Tx from './Tx.vue'
import { ArrowBigLeft, ArrowBigRight } from '@vicons/tabler'

@Options({
  components: {
    Connect,
    Completed,
    Ongoing,
    ArrowBigLeft,
    ArrowBigRight,
    Tx
  },
  props: {
    isRight: Boolean
  },
  data () {
    return {
      tab: 'Ongoing',
      ongoingList: [],
      completedList: [],
      dialog: null,
      msg: null,
      error: '',
      amount: 5,
      indexNum: '',
      currentPage: 0
    }
  },
  computed: {
    best () {
      return this.$store.state.best
    },
    network (): 'vechain' | 'ethereum' {
      return this.$store.state.network
    },
    address () {
      return this.$store.getters.currentAddress
    },
    list (): any[] {
      return this.tab === 'Completed' ? this.completedList : this.ongoingList
    },
    connectChain (): 'vechain' | 'ethereum' | '' {
      if (!this.$store.state.addresses.vechain && !this.$store.state.addresses.ethereum) {
        return ''
      } else {
        if (!this.address) {
          return this.network
        } else {
          return ''
        }
      }
    }
  },
  watch: {
    isRight () {
      this.fetchList(this.currentPage * this.amount, this.amount)
    },
    best () {
      this.fetchList(this.currentPage * this.amount, this.amount)
    },
    network () {
      this.currentPage = 0
      this.fetchList(0, this.amount)
    },
    address () {
      this.currentPage = 0
      this.fetchList(0, this.amount)
    },
    tab () {
      this.currentPage = 0
      this.fetchList(0, this.amount)
    }
  }
})
export default class History extends Vue {
  network!: 'vechain' | 'ethereum'
  address!: string
  tab!: 'Ongoing' | 'Completed'
  ongoingList!: unknown[]
  isRight!: boolean
  dialog!: DialogApi
  msg!: MessageApi
  completedList!: unknown[]
  error!: string
  amount!: number
  currentPage!: number
  list!: unknown[]
  indexNum!: string

  async fetchList (offset: number, limit: number): Promise<void> {
    if (!this.address || (!this.isRight && this.network === 'ethereum')) {
      this.currentPage = 0
      this.completedList = []
      this.ongoingList = []
      this.indexNum = ''
      return
    }

    const params: Record<string, string | number> = {
      chainname: this.network,
      chainid: config[this.network].chainId,
      address: this.address,
      filter: this.tab,
      limit,
      offset
    }

    const queryStr = Object.keys(params).map((key: string) => key + '=' + params[key]).join('&')
    const response = await fetch(`${config.api.baseUrl}/history?${queryStr}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const resp = await response.json()
    if (this.tab === 'Completed') {
      this.completedList = resp.history
    } else {
      this.ongoingList = resp.history
    }

    const from = this.currentPage * 5
    const to = from + this.list.length
    if (this.list.length) {
      this.indexNum = `${from + 1} - ${to}`
    } else {
      this.indexNum = ''
    }
  }

  prePage () {
    if (this.currentPage > 0) {
      this.currentPage--
      this.fetchList(this.currentPage * this.amount, this.amount)
    }
  }

  nextPage () {
    if (this.list.length === this.amount) {
      this.currentPage++
      this.fetchList(this.currentPage * this.amount, this.amount)
    }
  }

  created (): void {
    this.msg = useMessage()
    this.dialog = useDialog()
  }

  mounted (): void {
    this.fetchList(0, this.amount)
  }

  async claim (id: string, swapTx: string, symbol: string, decimals: number): Promise<void> {
    try {
      const temp = await fetch(`${config.api.baseUrl}/merkleproof?bridgetxid=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const resp = await temp.json()
      const meta = resp.meta
      let response
      if (this.network === 'vechain') {
        response = await (
          await this.$vet.claim(
            meta.receipt, meta.amount,
            meta.merkleProof, meta.swapCount,
            meta.root, this.address,
            symbol, decimals, meta.token)).txid
      } else {
        response = await (
          await this.$eth.claim(
            meta.receipt, meta.amount,
            meta.merkleProof, meta.swapCount,
            meta.root, this.address, meta.token)).hash
      }
      window.localStorage.setItem(swapTx, response)
    } catch (error: any) {
      const msg = (error.data && error.data.message) || error.message
      this.msg.warning(msg)
    }
  }

  onClaim ([id, swapTx, symbol, decimals]: any[]): void {
    const claimId = window.localStorage.getItem(swapTx)
    const hasClaimed = !!claimId
    if (hasClaimed) {
      this.dialog.warning({
        title: 'Claim again?',
        content: () => {
          return h('div', null,
            [
              'Your transaction may still be processing, it may take a while if the network has high volume of transactions.',
              h('br'),
              'You can check on your transaction',
              h(
                NButton,
                {
                  onClick: () => this.openTx(claimId!, this.network),
                  text: true,
                  style: 'margin-left: 5px',
                  color: '#2080f0'
                },
                [
                  h(
                    Tx,
                    {
                      id: claimId
                    }
                  )
                ]
              ),
              [' in the explorer']
            ])
        },
        positiveText: 'Claim',
        negativeText: 'I will wait',
        onPositiveClick: () => {
          this.claim(id, swapTx, symbol, decimals)
        }
      })
    } else {
      this.claim(id, swapTx, symbol, decimals)
    }
  }
}
