
import { Copy } from '@vicons/tabler'
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    content: String
  },
  data () {
    return {
      showPop: false
    }
  },
  components: {
    Copy
  }
})
export default class SwitchNetwork extends Vue {
  content!: string
  showPop!: boolean

  onCopy (): void {
    window.navigator.clipboard.writeText(this.content).then(() => {
      this.showPop = true
      setTimeout(() => {
        this.showPop = false
      }, 800)
    })
  }
}
