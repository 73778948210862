<template>
  <n-icon>
    <svg width="512px" height="512px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Artboard Copy 5</title>
    <g id="Artboard-Copy-5" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="vet" fill-rule="nonzero">
            <circle id="Oval" cx="256" cy="256" r="256"></circle>
        </g>
        <g id="vechain-logo--copy-2" transform="translate(77.000000, 93.000000)" fill="#28008C" fill-rule="nonzero">
            <path d="M359,0 L326.948092,0 C319.110113,0 311.967006,4.49608746 308.571283,11.5595564 L224.334684,186.902189 L224.24151,186.715861 L201.808042,233.419908 L201.894049,233.606237 L179.467747,280.295952 L67.3720777,46.7900458 L99.3523129,46.7900458 C107.190293,46.7929082 114.333399,51.2861333 117.729122,58.3496022 L190.885164,209.834973 L213.332967,163.109426 L154.217553,40.8490215 C142.255499,15.8900082 117.033839,0.0061994926 89.3540099,0 L0,0 L22.3976322,46.7900458 L22.4693046,46.7900458 L157.019944,327 L201.894049,327 L359,0 Z" id="Path"></path>
        </g>
    </g>
</svg>
  </n-icon>
</template>
