import BigNumber from 'bignumber.js'
import { balanceOf, Swap, SwapNativeCoin, approve, allowance, Claim, ClaimNativeCoin } from './Abi'
import { ethereum } from '../config'

const { Contract, BrowserProvider, parseEther } = window.ethers
export default class BridgeOnEth {
  provider!: typeof BrowserProvider
  eth!: typeof window.ethereum

  private targetId!: string
  private _bridge!: typeof Contract

  constructor (targetId: string) {
    if (window.ethereum && window.ethereum.isMetaMask) {
      this.targetId = targetId
      this.eth = window.ethereum
      if (parseInt(window.ethereum.chainId) === parseInt(targetId)) {
        this.provider = new BrowserProvider(window.ethereum)
        this._bridge = new Contract(ethereum.contracts.ftBridge, [Swap, SwapNativeCoin, Claim, ClaimNativeCoin])
      }
    } else {
      // TODO
    }
  }

  async initProvider () {
    await this.eth.request({ method: 'eth_chainId' })
    this.provider = new BrowserProvider(window.ethereum)
    this._bridge = new Contract(ethereum.contracts.ftBridge, [Swap, SwapNativeCoin, Claim, ClaimNativeCoin])
  }

  async getAccount () : Promise<string> {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      throw new Error('MetaMask required')
    }
    const account = await this.eth.request({
      method: 'eth_accounts'
    })
    return account.length ? account[0] : ''
  }

  get chainId (): string {
    return this.eth.chainId
  }

  get tId (): string {
    return this.targetId
  }

  public isMetaMask (): boolean {
    return window.ethereum && window.ethereum.isMetaMask
  }

  async requestAccount (): Promise<string> {
    if (!window.ethereum.isMetaMask) {
      throw new Error('MetaMask required')
    }
    const result = await this.eth.request({
      method: 'eth_requestAccounts'
    })
    return result[0] || ''
  }

  async connect (): Promise<string> {
    let result = ''
    result = await this.getAccount()
    result = result || await this.requestAccount()
    return result
  }

  getToken (addr: string) {
    const token = new Contract(addr, [balanceOf, approve, allowance])
    return {
      allowance: async (signer: string): Promise<BigNumber> => {
        const _signer = await this.provider.getSigner(signer)
        const t = await (token.connect(_signer) as typeof Contract).allowance(signer, ethereum.contracts.ftBridge)
        return new BigNumber(t)
      },
      approve: async (amount: string, signer: string): Promise<unknown> => {
        const _signer = await this.provider.getSigner(signer)
        return await (token.connect(_signer) as typeof Contract).approve(ethereum.contracts.ftBridge, '0x' + parseEther(amount).toString(16))
      },
      balance: async (addr: string): Promise<BigNumber> => {
        const _signer = await this.provider.getSigner(addr)
        const t = await (token.connect(_signer) as typeof Contract).balanceOf(addr)
        return new BigNumber(t)
      }
    }
  }

  async claim (address: string, amount: string, mp: string[], swapCount: string, root: string, signer: string, token?: string) {
    const _signer = await this.provider.getSigner(signer)
    if (token) {
      return await (this._bridge.connect(_signer) as typeof Contract).claim(token, address, amount, swapCount, root, mp)
    } else {
      return await (this._bridge.connect(_signer) as typeof Contract).claimNativeCoin(address, amount, swapCount, root, mp)
    }
  }

  async swap (to: string, amount: string, signer: string, token?: string | null) {
    const _signer = await this.provider.getSigner(signer)

    // token 18 decimals
    if (token) {
      return await (this._bridge.connect(_signer) as typeof Contract).swap(token, '0x' + parseEther(amount).toString(16), to)
    } else {
      return await (this._bridge.connect(_signer) as typeof Contract).swapNativeCoin(to, { value: '0x' + parseEther(amount).toString(16) })
    }
  }
}
