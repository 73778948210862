
import { Options, Vue } from 'vue-class-component'

const { isAddress, getAddress } = window.ethers
@Options({
  props: {
    addr: {
      type: String,
      required: true
    }
  },
  computed: {
    displayString (): string {
      if (!isAddress(this.addr)) {
        return ''
      }
      const checksumed = getAddress(this.addr)
      return checksumed.slice(0, 6) + '⋯' + checksumed.slice(-6)
    }
  }
})
export default class Address extends Vue {
  addr!: string
  displayString!: string
}
