export const Claim = {
  inputs: [
    {
      internalType: 'address',
      name: '_token',
      type: 'address'
    },
    {
      internalType: 'address',
      name: '_recipient',
      type: 'address'
    },
    {
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256'
    },
    {
      internalType: 'uint256',
      name: '_swapcount',
      type: 'uint256'
    },
    {
      internalType: 'bytes32',
      name: '_root',
      type: 'bytes32'
    },
    {
      internalType: 'bytes32[]',
      name: '_merkleProof',
      type: 'bytes32[]'
    }
  ],
  name: 'claim',
  outputs: [
    {
      internalType: 'bool',
      name: '',
      type: 'bool'
    }
  ],
  stateMutability: 'nonpayable',
  type: 'function'
}

export const Swap = {
  inputs: [
    {
      internalType: 'address',
      name: '_token',
      type: 'address'
    },
    {
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256'
    },
    {
      internalType: 'address',
      name: '_recipient',
      type: 'address'
    }
  ],
  name: 'swap',
  outputs: [
    {
      internalType: 'bool',
      name: '',
      type: 'bool'
    }
  ],
  stateMutability: 'nonpayable',
  type: 'function'
}

export const SwapNativeCoin = {
  inputs: [
    {
      internalType: 'address',
      name: '_recipient',
      type: 'address'
    }
  ],
  name: 'swapNativeCoin',
  outputs: [
    {
      internalType: 'bool',
      name: '',
      type: 'bool'
    }
  ],
  stateMutability: 'payable',
  type: 'function'
}

export const ClaimNativeCoin = {
  inputs: [
    {
      internalType: 'address payable',
      name: '_recipient',
      type: 'address'
    },
    {
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256'
    },
    {
      internalType: 'uint256',
      name: '_swapcount',
      type: 'uint256'
    },
    {
      internalType: 'bytes32',
      name: '_root',
      type: 'bytes32'
    },
    {
      internalType: 'bytes32[]',
      name: '_merkleProof',
      type: 'bytes32[]'
    }
  ],
  name: 'claimNativeCoin',
  outputs: [
    {
      internalType: 'bool',
      name: '',
      type: 'bool'
    }
  ],
  stateMutability: 'nonpayable',
  type: 'function'
}

export const allowance = {
  inputs: [
    {
      internalType: 'address',
      name: '',
      type: 'address'
    },
    {
      internalType: 'address',
      name: '',
      type: 'address'
    }
  ],
  name: 'allowance',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}

export const approve = {
  inputs: [
    {
      internalType: 'address',
      name: 'guy',
      type: 'address'
    },
    {
      internalType: 'uint256',
      name: 'wad',
      type: 'uint256'
    }
  ],
  name: 'approve',
  outputs: [
    {
      internalType: 'bool',
      name: '',
      type: 'bool'
    }
  ],
  stateMutability: 'nonpayable',
  type: 'function'
}

export const balanceOf = {
  inputs: [
    {
      internalType: 'address',
      name: '',
      type: 'address'
    }
  ],
  name: 'balanceOf',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function'
}
