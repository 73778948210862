<template>
  <n-icon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 474.73"
    >
      <g
        id="图层_2"
        data-name="图层 2"
      >
        <g
          id="图层_1-2"
          data-name="图层 1"
        >
          <polygon
            class="cls-1"
            points="486.05 0.5 286.66 148.59 323.53 61.22 486.05 0.5"
          />
          <polygon
            class="cls-2"
            points="25.75 0.5 223.54 149.99 188.47 61.22 25.75 0.5"
          />
          <polygon
            class="cls-2"
            points="414.31 343.77 361.21 425.13 474.83 456.39 507.49 345.57 414.31 343.77"
          />
          <polygon
            class="cls-2"
            points="4.71 345.57 37.17 456.39 150.79 425.13 97.69 343.77 4.71 345.57"
          />
          <polygon
            class="cls-2"
            points="144.38 206.3 112.72 254.2 225.54 259.21 221.53 137.97 144.38 206.3"
          />
          <polygon
            class="cls-2"
            points="367.42 206.3 289.26 136.57 286.66 259.21 399.28 254.2 367.42 206.3"
          />
          <polygon
            class="cls-2"
            points="150.79 425.13 218.53 392.07 160.01 346.38 150.79 425.13"
          />
          <polygon
            class="cls-2"
            points="293.27 392.07 361.21 425.13 351.79 346.38 293.27 392.07"
          />
          <polygon
            class="cls-3"
            points="361.21 425.13 293.27 392.07 298.68 436.35 298.08 454.99 361.21 425.13"
          />
          <polygon
            class="cls-3"
            points="150.79 425.13 213.92 454.99 213.52 436.35 218.53 392.07 150.79 425.13"
          />
          <polygon
            class="cls-4"
            points="214.92 317.12 158.41 300.49 198.29 282.25 214.92 317.12"
          />
          <polygon
            class="cls-4"
            points="296.88 317.12 313.51 282.25 353.59 300.49 296.88 317.12"
          />
          <polygon
            class="cls-5"
            points="150.79 425.13 160.41 343.77 97.69 345.57 150.79 425.13"
          />
          <polygon
            class="cls-5"
            points="351.59 343.77 361.21 425.13 414.31 345.57 351.59 343.77"
          />
          <polygon
            class="cls-5"
            points="399.28 254.2 286.66 259.21 297.08 317.12 313.71 282.25 353.79 300.49 399.28 254.2"
          />
          <polygon
            class="cls-5"
            points="158.41 300.49 198.49 282.25 214.92 317.12 225.54 259.21 112.72 254.2 158.41 300.49"
          />
          <polygon
            class="cls-6"
            points="112.72 254.2 160.01 346.38 158.41 300.49 112.72 254.2"
          />
          <polygon
            class="cls-6"
            points="353.79 300.49 351.79 346.38 399.28 254.2 353.79 300.49"
          />
          <polygon
            class="cls-6"
            points="225.54 259.21 214.92 317.12 228.15 385.45 231.15 295.48 225.54 259.21"
          />
          <polygon
            class="cls-6"
            points="286.66 259.21 281.25 295.28 283.65 385.45 297.08 317.12 286.66 259.21"
          />
          <polygon
            class="cls-7"
            points="297.08 317.12 283.65 385.45 293.27 392.07 351.79 346.38 353.79 300.49 297.08 317.12"
          />
          <polygon
            class="cls-7"
            points="158.41 300.49 160.01 346.38 218.53 392.07 228.15 385.45 214.92 317.12 158.41 300.49"
          />
          <polygon
            class="cls-8"
            points="298.08 454.99 298.68 436.35 293.67 431.94 218.13 431.94 213.52 436.35 213.92 454.99 150.79 425.13 172.84 443.17 217.53 474.23 294.27 474.23 339.16 443.17 361.21 425.13 298.08 454.99"
          />
          <polygon
            class="cls-9"
            points="293.27 392.07 283.65 385.45 228.15 385.45 218.53 392.07 213.52 436.35 218.13 431.94 293.67 431.94 298.68 436.35 293.27 392.07"
          />
          <polygon
            class="cls-10"
            points="494.47 158.21 511.5 76.45 486.05 0.5 293.27 143.58 367.42 206.3 472.22 236.96 495.47 209.91 485.45 202.7 501.48 188.07 489.06 178.45 505.09 166.22 494.47 158.21"
          />
          <polygon
            class="cls-10"
            points="0.5 76.45 17.53 158.21 6.71 166.22 22.74 178.45 10.52 188.07 26.55 202.7 16.53 209.91 39.58 236.96 144.38 206.3 218.53 143.58 25.75 0.5 0.5 76.45"
          />
          <polygon
            class="cls-7"
            points="472.22 236.96 367.42 206.3 399.28 254.2 351.79 346.38 414.31 345.57 507.49 345.57 472.22 236.96"
          />
          <polygon
            class="cls-7"
            points="144.38 206.3 39.58 236.96 4.71 345.57 97.69 345.57 160.01 346.38 112.72 254.2 144.38 206.3"
          />
          <polygon
            class="cls-7"
            points="286.66 259.21 293.27 143.58 323.73 61.22 188.47 61.22 218.53 143.58 225.54 259.21 227.94 295.68 228.15 385.45 283.65 385.45 284.06 295.68 286.66 259.21"
          />
        </g>
      </g>
    </svg>
  </n-icon>
</template>

<style>
.cls-1 {
  fill: #d97420;
  stroke: #d97420;
}
.cls-1,
.cls-10,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7,
.cls-8,
.cls-9 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-2 {
  fill: #db7420;
  stroke: #db7420;
}

.cls-3 {
  fill: #d6bfb2;
  stroke: #d6bfb2;
}

.cls-4 {
  fill: #223446;
  stroke: #223446;
}

.cls-5 {
  fill: #c5611b;
  stroke: #c5611b;
}

.cls-6 {
  fill: #db7324;
  stroke: #db7324;
}

.cls-7 {
  fill: #ec8323;
  stroke: #ec8323;
}

.cls-8 {
  fill: #bfac9e;
  stroke: #bfac9e;
}

.cls-9 {
  fill: #161616;
  stroke: #161616;
}

.cls-10 {
  fill: #743d1e;
  stroke: #743d1e;
}
</style>
