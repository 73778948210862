import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'

declare module '@vue/runtime-core' {
  // declare your own store states
  export interface State {
    network: 'ethereum' | 'vechain'
    addresses: Record<string, string | null>,
    balance: Record<string, string> | null,
    best: number
  }

  // provide typings for `this.$store`
  interface ComponentCustomProperties {
    $store: Store<State>
  }
}

export interface State {
  network: 'ethereum' | 'vechain',
  addresses: Record<string, string | null>,
  balances: Record<string, string> | null,
  best: number
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol('v')

export const store = createStore<State>({
  state () {
    return {
      network: 'ethereum',
      addresses: {
        vechain: null,
        ethereum: null
      },
      best: 0,
      balances: null
    }
  },
  getters: {
    currentAddress (state: State) {
      return state.network === 'ethereum' ? state.addresses.ethereum : state.addresses.vechain
    }
  },
  mutations: {
    setBestNum (state, payload: {num: number}) {
      state.best = payload.num
    },
    changeNetwork (state) {
      if (state.network === 'ethereum') {
        state.network = 'vechain'
      } else {
        state.network = 'ethereum'
      }
    },
    setAddress (state, payload: { key: 'vechain' | 'ethereum', addr: string }) {
      state.addresses[payload.key] = payload.addr
      state.network = payload.key
    },
    delBalance (state, payload: { address: string, network: 'ethereum' | 'vechain' }) {
      if (state.balances) {
        Object.keys(state.balances).forEach(key => {
          if (key.includes(`${payload.network}-${payload.address.toLowerCase()}`)) {
            delete state.balances![key]
          }
        })
      }
    },
    setBalance (state, payload: { address: string, network: 'ethereum' | 'vechain', symbol: string, amount: string }) {
      const { address, network, symbol, amount } = { ...payload }
      if (!state.balances) {
        state.balances = {}
      }
      state.balances[`${network}-${address.toLowerCase()}-${symbol.toLowerCase()}`] = amount
    }
  }
})
