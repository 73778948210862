
import { ArrowsSort } from '@vicons/tabler'
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    isIcon: Boolean
  },
  components: {
    ArrowsSort
  }
})
export default class SwitchNetwork extends Vue {
  isIcon!: boolean

  changeNetwork (): void {
    this.$store.commit('changeNetwork')
  }
}
