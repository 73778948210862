
import { Options, Vue } from 'vue-class-component'

import { CircleCheck, Clock } from '@vicons/tabler'
import Balance from '../Balance.vue'
import Address from '../Address.vue'
import ETH from '../icons/Eth.vue'
import VET from '../icons/Vet.vue'
import VETH from '../icons/Veth.vue'
import WVET from '../icons/Wvet.vue'
import vechain from '../icons/Vechain.vue'
import ethereum from '../icons/Ethereum.vue'
import Copy from '../Copy.vue'
import Tx from '../Tx.vue'

@Options({
  components: {
    Balance,
    Address,
    ETH,
    VET,
    VETH,
    WVET,
    vechain,
    ethereum,
    Tx,
    Copy,
    CircleCheck,
    Clock
  },
  props: {
    item: Object,
    isCompleted: Boolean
  },
  computed: {
    title (): string {
      return `Transferred ${this.item.from.symbol}`
    },
    comp (): string {
      return this.item.to.symbol.to
    }
  }
})
export default class Transfer extends Vue {
  item!: unknown
}
