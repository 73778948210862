import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_avatar = _resolveComponent("n-avatar")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_n_page_header = _resolveComponent("n-page-header")!

  return (_openBlock(), _createBlock(_component_n_page_header, { class: "page-header" }, {
    avatar: _withCtx(() => [
      _createVNode(_component_n_avatar, {
        color: "none",
        src: _ctx.img
      }, null, 8, ["src"])
    ]),
    subtitle: _withCtx(() => [
      _renderSlot(_ctx.$slots, "sub", {}, undefined, true)
    ]),
    extra: _withCtx(() => [
      _createVNode(_component_n_space, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}