
import { Vue, Options } from 'vue-class-component'
@Options({
  data () {
    return {
      img: require('../assets/logo.svg')
    }
  }
})
export default class PageHeader extends Vue {
}
