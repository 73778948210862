import { ethereum, vechain } from './config'
export const ethList = [
  {
    label: 'ETH',
    decimals: 18,
    value: 'ETH',
    address: '',
    network: 'ethereum'
  }, {
    label: 'wVET',
    decimals: 18,
    value: 'wVET',
    address: ethereum.contracts.wVET,
    network: 'ethereum'
  }
]

export const vetList = [
  {
    label: 'VET',
    decimals: 18,
    value: 'VET',
    address: '',
    network: 'vechain'
  }, {
    label: 'vETH',
    decimals: 18,
    value: 'vETH',
    address: vechain.contracts.vETH,
    network: 'vechain'
  }
]
