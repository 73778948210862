
import { Options, Vue } from 'vue-class-component'
import Address from './Address.vue'
import ethereum from './icons/Ethereum.vue'
import vechain from './icons/Vechain.vue'
import BigNumber from 'bignumber.js'
import { Loader, AlertCircle, CircleCheck } from '@vicons/tabler'

const { parseEther } = window.ethers
type RPCError = {
  code: number
  message: string
}

@Options({
  data () {
    return {
      show: false,
      status: '',
      active: 1,
      checking: true,
      approving: false,
      approved: false,
      myPromise: null,
      rejectMsg: {
        title: 'User Canceled',
        // eslint-disable-next-line quotes
        msg: `You'd reject to sign the transaction`
      }
    }
  },
  components: {
    Address,
    ethereum,
    vechain,
    CircleCheck,
    AlertCircle,
    Loader
  },
  props: {
    content: Array
  },
  computed: {
    network (): 'ethereum' | 'vechain' {
      return this.$store.state.network
    },
    needApprove () {
      return !!this.content[0].token && this.network === 'ethereum'
    }
  }
})
export default class ConfirmDialog extends Vue {
  show!: boolean
  approving!: boolean
  checking!: boolean
  status!: 'confirming' | 'rejected' | 'signed' | ''
  approved!: boolean
  active!: number
  content!: {
    wallet: string,
    token: string | null,
    amount: string,
    symbol: string,
    network: 'vechain' | 'ethereum'
  }[]

  rejectMsg!: {
    title: string,
    msg: string
  }

  myPromise!: Promise<unknown>
  resolve!: (v: any) => void
  reject!: (v: any) => void

  async statusCheck (): Promise<void> {
    const agent = this.$eth
    const isAllowanceOk = await this.checkAllowance()

    if (!isAllowanceOk && this.checkLocalTx()) {
      const [from] = this.content!
      const lastApprStr = localStorage.getItem(`lastApprove-${from.symbol}`)
      const lastAppr = lastApprStr ? JSON.parse(lastApprStr) : ''
      this.checking = false
      try {
        this.approving = true
        const receipt = await agent.provider.waitForTransaction(lastAppr.txid, 1)
        if (receipt) {
          this.approving = false
          this.approved = receipt.status === 1
          this.active = 2
        }
      } catch (error) {
        if (this.show) {
          this.status = 'rejected'
        }
      }
    }

    if (isAllowanceOk) {
      this.approved = true
      this.active = 2
    }

    this.checking = false
  }

  onShow () {
    this.statusCheck()
  }

  // show
  popUp (): Promise<any> {
    this.show = true
    const result = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
    this.myPromise = result
    return result
  }

  onClose (): void {
    this.reject && this.reject('closed')
    this.approved = false
    this.approving = false
    this.active = 1
    this.checking = true
    this.status = ''
    this.rejectMsg = {
      title: 'User Canceled',
      // eslint-disable-next-line quotes
      msg: `You'd reject to sign the transaction`
    }
    this.resolve && this.resolve('')
    this.reject && this.reject('')
    this.myPromise = null as unknown as Promise<unknown>
    this.resolve = null as unknown as () => void
    this.reject = null as unknown as () => void
  }

  checkLocalTx (): boolean {
    const [from] = this.content
    const lastApprStr = localStorage.getItem(`lastApprove-${from.symbol}`)
    const lastAppr = lastApprStr ? JSON.parse(lastApprStr) : ''
    return lastAppr && lastAppr.signer === from.wallet && new BigNumber(from.amount).lte(lastAppr.amount)
  }

  async checkAllowance (): Promise<boolean> {
    let result = false
    try {
      const [from] = this.content
      if (from.network === 'ethereum' && from.token) {
        const token = this.$eth.getToken(from.token)
        this.checking = true
        const allowance = await token.allowance(from.wallet)
        result = allowance.gte(new BigNumber('0x' + parseEther(from.amount).toString(16)))
      } else {
        result = true
      }
    } catch (error) {
      console.error('On check allowance', error)
    }
    return result
  }

  async onApprove (): Promise<void> {
    const from = this.content[0]
    const token = this.$eth.getToken(from.token!)
    try {
      this.status = 'confirming'
      this.approving = true
      const tx: any = await token.approve(from.amount, from.wallet)
      localStorage.setItem(`lastApprove-${from.symbol}`, JSON.stringify({
        txid: tx.hash,
        amount: from.amount,
        signer: from.wallet
      }))
      const temp = new Promise((resolve) => {
        this.$eth.provider.waitForTransaction(tx.hash, 1).then((receipt: any) => {
          if (receipt) {
            this.approving = false
            this.approved = receipt.status === 1
            this.active = 2
          }
        }).finally(() => {
          localStorage.removeItem(`lastApprove-${from.symbol}`)
          resolve('finished')
        })
      })
      this.status = ''
    } catch (error) {
      if (this.show) {
        console.error('on approve: ', error)
        this.status = 'rejected'
      }
    }
  }

  async signASend (): Promise<any> {
    const [from, to] = this.content
    if (from.network === 'ethereum') {
      return this.$eth.swap(to.wallet, from.amount, from.wallet, from.token)
    } else {
      return this.$vet.swap(to.wallet, from.amount, from.wallet, from.token, from.symbol)
    }
  }

  async swap (): Promise<void> {
    try {
      this.status = 'confirming'
      const tx = await Promise.race([this.signASend(), this.myPromise])
      this.resolve && this.resolve('')

      console.info('TODO tx link:', tx)
      this.status = 'signed'
    } catch (error) {
      console.log('error on swap:', error)
      const err = error as RPCError
      this.rejectMsg = {
        title: 'Transaction Rejected',
        msg: err.message
      }
      if (this.show) {
        this.status = 'rejected'
      }
    }
  }
}
