
import { Options, Vue } from 'vue-class-component'
@Options({
  props: {
    id: String
  }
})
export default class Tx extends Vue {
  id!: string
  get str (): string {
    return this.id ? this.id.substr(0, 8) + '…' : '--'
  }
}
