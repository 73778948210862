
import { Options, Vue } from 'vue-class-component'
import Eth from './icons/Ethereum.vue'
import Vet from './icons/Vechain.vue'
import Address from './Address.vue'
import Disconnect from './Disconnect.vue'
import SwitchNetwork from './SwitchNetwork.vue'

@Options({
  data () {
    return {
      show: false
    }
  },
  props: {
    addresses: Object,
    isRight: Boolean,
    network: String
  },
  components: {
    Eth,
    Vet,
    Address,
    SwitchNetwork,
    Disconnect
  },
  computed: {
    logo (): string {
      if (this.network === 'ethereum') {
        return 'Eth'
      } else {
        return 'Vet'
      }
    },
    text (): string {
      return this.network === 'vechain' ? this.addresses.vechain : this.addresses.ethereum
    }
  }
})
export default class ConnectStatus extends Vue {
  addresses!: Record<'vet' | 'eth', string | null>
  network!: 'vechain' | 'ethereum'
  isRight!: boolean
  logo!: string
  show!: boolean

  onOutside () {
    this.show = false
  }

  onlistClick (e: MouseEvent) {
    if (e.target && (e.target as HTMLElement).classList.value.includes('n-button')) {
      this.show = false
    }
  }
}
