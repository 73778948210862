
import { Options, Vue } from 'vue-class-component'
import Receive from './Receive.vue'
import Transfer from './Transfer.vue'

@Options({
  components: {
    Receive,
    Transfer
  },
  props: {
    list: Array,
    network: String
  }
})
export default class CompletedList extends Vue { }
