/* eslint-disable */
export const vechain = {
  nodeHost: process.env.VUE_APP_VECHAIN_NODE!,
  chainName: 'vechain',
  chainId: process.env.VUE_APP_VECHAIN_ID!,
  contracts: {
    ftBridge: process.env.VUE_APP_VECHAIN_BRIDGE!,
    vETH: process.env.VUE_APP_VECHAIN_ETH!
  }
}

export const ethereum = {
  nodeHost: process.env.VUE_APP_ETH_NODE!,
  chainName: 'ethereum',
  chainId: process.env.VUE_APP_ETH_ID!,
  contracts: {
    ftBridge: process.env.VUE_APP_ETH_BRIDGE!,
    wVET: process.env.VUE_APP_ETH_VET!
  }
}
export const api = {
  baseUrl: process.env.VUE_APP_HOST_API!
}
