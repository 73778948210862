
import { Options, Vue } from 'vue-class-component'
import PageHeader from './components/PageHeader.vue'
import ConnectStatus from './components/ConnectStatus.vue'
import swap from './components/Swap.vue'
import Address from './components/Address.vue'
import history from './components/History.vue'
import Connect from './components/Connect.vue'
import faucet from './components/Faucet.vue'
import WalletOnVet from './components/status/WalletOnVet.vue'
import WalletOnEth from './components/status/WalletOnEth.vue'
import { vetList, ethList } from './const'

@Options({
  data () {
    return {
      showConnect: false,
      connectChain: '',
      themeOverrides: {
        Steps: {
          descriptionTextColorProcess: '#666666'
        }
      },
      comp: 'Swap',
      tab: 'swap',
      isRightChain: true,
      tokkensOnVechain: [
        {
          address: vetList[1].address,
          symbol: vetList[1].value
        }
      ],
      tokkensOnEthereum: [
        {
          address: ethList[1].address,
          symbol: ethList[1].value
        }
      ]
    }
  },
  components: {
    PageHeader,
    swap,
    ConnectStatus,
    history,
    Address,
    faucet,
    WalletOnVet,
    WalletOnEth,
    Connect
  },
  computed: {
    addresses (): Record<'vechain' | 'ethereum', string | null> {
      return this.$store.state.addresses
    },
    network (): string {
      return this.$store.state.network
    }
  }
})
export default class App extends Vue {
  addresses!: Record<'vet' | 'eth', string | null>
  comp!: string
  isRightChain!: boolean
  tab!: 'swap' | 'history' | 'faucet'
  showConnect!: boolean
  connectChain!: string

  setComp (hash: string): void {
    if (hash === 'history') {
      this.tab = 'history'
      this.comp = 'history'
    } else if (hash === 'faucet') {
      this.tab = 'faucet'
      this.comp = 'faucet'
    } else {
      this.tab = 'swap'
      this.comp = 'swap'
      window.location.hash = '#swap'
    }
  }

  onConnect ([net]: any[]) {
    this.showConnect = true
    this.connectChain = net
  }

  onChange ([isRight]: any[]): void {
    this.isRightChain = isRight
  }

  onTabChange (v: 'swap' | 'history' | 'faucet') {
    this.tab = v
    this.comp = v
    window.location.hash = `#${v}`
  }

  created () {
    const hash = window.location.hash.substr(1)
    this.setComp(hash)
  }
}
