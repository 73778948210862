
import { Options, Vue } from 'vue-class-component'
import { BigNumber } from 'bignumber.js'
@Options({
  props: {
    balance: String,
    decimals: {
      type: Number,
      default: 18
    }
  }
})
export default class Balance extends Vue {
  balance!: string
  decimals!: number

  get formatted (): string {
    if (!this.balance) {
      return '--.--'
    }
    if (this.balance === '0x') {
      return '0.00'
    }
    const bn = new BigNumber(this.balance)
    return bn.dividedBy(`1e${this.decimals}`).toFormat(3, 1)
  }
}
