<template>
  <n-icon v-bind="$attrs">
    <div>
      <img
        style="width: 100%; height: 100%"
        src="../../assets/ethereum.png"
      >
    </div>
  </n-icon>
</template>
