import { Connex } from '@vechain/connex'
import { BigNumber } from 'bignumber.js'
import { vechain } from '../config'
import { Swap, SwapNativeCoin, allowance, balanceOf, approve, Claim, ClaimNativeCoin } from './Abi'

const { getAddress, parseEther } = window.ethers

function SignTx (connex: Connex, msg: Connex.Vendor.TxMessage) {
  const url = process.env.VUE_APP_DELEGATE
  const sign = connex.vendor.sign('tx', msg)
  if (url) {
    sign.delegate(url)
  }
  return sign
}

export default class BridgeOnVet {
  public connex
  private bridge!: Connex.Thor.Account.Visitor

  constructor (connex: Connex) {
    this.connex = connex
    this.bridge = this.connex.thor.account(vechain.contracts.ftBridge)
  }

  async fetchBalance (wallet: string, tokens: string[]): Promise<string[]> {
    const clauses: Connex.VM.Clause[] = []

    tokens.forEach(item => {
      const token = this.connex.thor.account(item)
      clauses.push(token.method(balanceOf).asClause(wallet))
    })
    const t = await this.connex.thor.explain(clauses).execute()
    return t.map((item) => item.data)
  }

  getToken (address: string) {
    const token = this.connex.thor.account(address)
    return {
      allowance: async (address: string) => {
        const resp = await token.method(allowance).call(address, vechain.contracts.ftBridge)
        return new BigNumber(resp.decoded[0])
      },
      approve: (amount: string) => {
        return token.method(approve).asClause(vechain.contracts.ftBridge, '0x' + parseEther(amount).toString(16))
      },
      balance: async (address: string) => {
        const resp = await token.method(balanceOf).call(address)
        return new BigNumber(resp.decoded[0])
      }
    }
  }

  async connect (): Promise<string> {
    const result = await this.connex.vendor.sign('cert', {
      purpose: 'identification',
      payload: {
        type: 'text',
        content: 'Bridge get the address'
      }
    }).request()

    return result.annex.signer
  }

  async claim (address: string, amount: string, mp: string[], swapCount: string, root: string, signer: string, symbol?: string, decimals?: number, token?: string) {
    const _amount = new BigNumber(amount).dividedBy(`1e${decimals}`).toFormat(3, 1)
    if (token) {
      const clause = this.bridge.method(Claim).asClause(token, address, amount, swapCount, root, mp)
      return await SignTx(this.connex, [clause]).signer(signer)
        .comment(`Claiming ${_amount} ${symbol}`).request()
    } else {
      const clause = this.bridge.method(ClaimNativeCoin).asClause(address, amount, swapCount, root, mp)
      return await SignTx(this.connex, [clause])
        .signer(signer).comment(`Claiming ${_amount} VET`).request()
    }
  }

  async swap (to: string, amount: string, signer: string, token?: string | null, symbol?: string) {
    if (token) {
      return this.swapToken(to, amount, signer, token, symbol!)
    } else {
      return this.swapCoin(to, amount, signer)
    }
  }

  async swapToken (to: string, amount: string, signer: string, token: string, symbol: string) {
    const tokenAcc = this.getToken(token)
    const checksumed = getAddress(to)
    const addr = checksumed.slice(0, 6) + '⋯' + checksumed.slice(-6)
    const allowance = await tokenAcc.allowance(signer)
    const clauses = []
    if (allowance.dividedBy(1e18).isLessThan(amount)) {
      clauses.push({
        ...tokenAcc.approve(amount),
        comment: `Allow to transfer your ${amount} ${symbol}`
      })
    }
    clauses.push({
      ...this.bridge.method(Swap).asClause(token, '0x' + parseEther(amount).toString(16), to),
      comment: `Transfer ${amount} ${symbol} to ${addr}`
    })
    return await SignTx(this.connex, clauses)
      .signer(signer).comment(`Transferring ${amount} ${symbol} to ${addr}`).request()
  }

  async swapCoin (to: string, amount: string, signer: string) {
    const checksumed = getAddress(to)
    const addr = checksumed.slice(0, 6) + '⋯' + checksumed.slice(-6)
    const clause = this.bridge.method(SwapNativeCoin).value('0x' + parseEther(amount).toString(16)).asClause(to)
    return await SignTx(this.connex, [{
      ...clause,
      comment: `Transfer ${amount} VET to ${addr}`
    }])
      .signer(signer).comment(`Transferring ${amount} VET to ${addr}`).request()
  }
}
