import { createApp } from 'vue'
import { store, key } from './store'
import Connex from '@vechain/connex'
import App from './App.vue'
import naive from './naive'
import './registerServiceWorker'
import Vet from './chain/Vet'
import Eth from './chain/Eth'
import { ethereum, vechain } from './config'

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $vet: Vet
    $eth: Eth
    openTx: (tx: string, network: 'vechain' | 'ethereum') => void
  }
}
declare global {
  interface Window {
    ethers: any,
    ethereum: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      request: (params: { method: string, params?: | Array<any> | Record<string, unknown> }) => Promise<any>
      isMetaMask: boolean
      chainId: string
      on(eventName: string, listener: (...args: Array<any>) => void): void
      removeAllListeners(): void,
      removeListener(name: string, cb: (b: any) => void): void
    }
  }
}

if (window.ethereum) {
  window.ethereum.request({ method: 'eth_chainId' }).then(() => {
    initApp()
  })
} else {
  initApp()
}

function initApp () {
  const app = createApp(App)
  app.config.globalProperties.$eth = new Eth(ethereum.chainId.toString())
  app.config.globalProperties.openTx = (tx: string, network: 'vechain' | 'ethereum') => {
    let url = ''
    if (network === 'vechain') {
      url = process.env.VUE_APP_VECHAIN_EXPLORER!
    } else {
      url = process.env.VUE_APP_ETH_EXPLORER!
    }

    if (url) {
      window.open(url.replace('{tx}', tx), '_blank')
    }
  }
  app.config.globalProperties.$vet = new Vet(new Connex({
    node: vechain.nodeHost,
    network: process.env.VUE_APP_VECHAIN_NETWORK as 'main' | 'test'
  }))

  app.directive('number', {
    mounted (el) {
      const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'ArrowDown', 'ArrowUp']
      const mk = ['a', 'c', 'v', 'x']
      el.addEventListener('keydown', (event: KeyboardEvent) => {
        if (!keys.includes(event.key) && !(mk.includes(event.key) && (event.metaKey || event.ctrlKey))) {
          event.cancelBubble = true
          event.preventDefault()
          event.stopPropagation()
        }
      })
    }
  })
  app.use(store, key)
  app.use(naive)
  app.mount('#app')
}
