import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-color-888" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_tab_pane = _resolveComponent("n-tab-pane")!
  const _component_n_tabs = _resolveComponent("n-tabs")!
  const _component_ArrowBigLeft = _resolveComponent("ArrowBigLeft")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_ArrowBigRight = _resolveComponent("ArrowBigRight")!
  const _component_n_button_group = _resolveComponent("n-button-group")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_n_card = _resolveComponent("n-card")!
  const _component_Connect = _resolveComponent("Connect")!

  return (_ctx.address)
    ? (_openBlock(), _createBlock(_component_n_card, {
        key: 0,
        style: {"width":"450px","margin":"auto"},
        title: "History"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_n_tabs, {
            value: _ctx.tab,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_tab_pane, {
                name: "Ongoing",
                tab: "Ongoing"
              }),
              _createVNode(_component_n_tab_pane, {
                name: "Completed",
                tab: "Completed"
              })
            ]),
            _: 1
          }, 8, ["value"]),
          _createVNode(_component_n_space, {
            justify: "space-between",
            style: {"margin-bottom":"10px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_button_group, null, {
                default: _withCtx(() => [
                  _createVNode(_component_n_button, {
                    disabled: _ctx.currentPage === 0,
                    size: "small",
                    onClick: _ctx.prePage,
                    round: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_n_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ArrowBigLeft)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"]),
                  _createVNode(_component_n_button, {
                    disabled: _ctx.list.length < _ctx.amount,
                    size: "small",
                    onClick: _ctx.nextPage,
                    round: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_n_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ArrowBigRight)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.indexNum), 1)
            ]),
            _: 1
          }),
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tab), {
            onClaim: _ctx.onClaim,
            network: _ctx.network,
            list: _ctx.list
          }, null, 8, ["onClaim", "network", "list"]))
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_Connect, {
        key: 1,
        network: _ctx.connectChain,
        title: "Connect your wallet",
        style: {"width":"450px","margin":"auto"}
      }, null, 8, ["network"]))
}