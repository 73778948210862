import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_tab_pane = _resolveComponent("n-tab-pane")!
  const _component_n_tabs = _resolveComponent("n-tabs")!
  const _component_ConnectStatus = _resolveComponent("ConnectStatus")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_n_layout_header = _resolveComponent("n-layout-header")!
  const _component_n_dialog_provider = _resolveComponent("n-dialog-provider")!
  const _component_n_message_provider = _resolveComponent("n-message-provider")!
  const _component_n_layout_content = _resolveComponent("n-layout-content")!
  const _component_n_layout = _resolveComponent("n-layout")!
  const _component_Connect = _resolveComponent("Connect")!
  const _component_n_modal = _resolveComponent("n-modal")!
  const _component_WalletOnVet = _resolveComponent("WalletOnVet")!
  const _component_WalletOnEth = _resolveComponent("WalletOnEth")!
  const _component_n_config_provider = _resolveComponent("n-config-provider")!

  return (_openBlock(), _createBlock(_component_n_config_provider, { "theme-overrides": _ctx.themeOverrides }, {
    default: _withCtx(() => [
      _createVNode(_component_n_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_n_layout_header, { position: "static" }, {
            default: _withCtx(() => [
              _createVNode(_component_PageHeader, null, {
                sub: _withCtx(() => [
                  _createVNode(_component_n_tabs, {
                    size: "small",
                    "pane-class": "none",
                    value: _ctx.tab,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
                    "on-update:value": _ctx.onTabChange
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_n_tab_pane, {
                        name: "swap",
                        tab: "Bridge"
                      }),
                      _createVNode(_component_n_tab_pane, {
                        name: "history",
                        tab: "History"
                      }),
                      _createVNode(_component_n_tab_pane, {
                        name: "faucet",
                        tab: "Faucet"
                      })
                    ]),
                    _: 1
                  }, 8, ["value", "on-update:value"])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_ConnectStatus, {
                    onConnect: _ctx.onConnect,
                    addresses: _ctx.addresses,
                    isRight: _ctx.isRightChain,
                    network: _ctx.network
                  }, null, 8, ["onConnect", "addresses", "isRight", "network"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_n_layout_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_n_message_provider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_n_dialog_provider, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.comp), {
                        onConnect: _ctx.onConnect,
                        isRight: _ctx.isRightChain
                      }, null, 8, ["onConnect", "isRight"]))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_n_modal, {
        "transform-origin": "center",
        style: {"width":"400px"},
        show: _ctx.showConnect,
        "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showConnect) = $event)),
        preset: "card",
        title: "Connect to a wallet"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Connect, {
            network: _ctx.connectChain,
            onConnected: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showConnect = false)),
            bordered: false
          }, null, 8, ["network"])
        ]),
        _: 1
      }, 8, ["show"]),
      _createVNode(_component_n_message_provider, {
        placement: "top-right",
        "container-style": {'margin-top': '50px'}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_WalletOnVet, {
            wallet: _ctx.addresses.vechain,
            tokens: _ctx.tokkensOnVechain
          }, null, 8, ["wallet", "tokens"]),
          _createVNode(_component_WalletOnEth, {
            onNetworkChange: _ctx.onChange,
            wallet: _ctx.addresses.ethereum,
            tokens: _ctx.tokkensOnEthereum
          }, null, 8, ["onNetworkChange", "wallet", "tokens"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["theme-overrides"]))
}