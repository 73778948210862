
import { h } from 'vue'
import { Options, Vue } from 'vue-class-component'
import { useMessage, MessageApi, NAlert } from 'naive-ui'

const { getAddress } = window.ethers
@Options({
  emits: ['networkChange'],
  props: {
    wallet: String,
    tokens: Array
  },
  watch: {
    wallet (v: string) {
      if (!this.isRight && v) {
        this.msgWrongNetwork()
      }
      this.fetchBalance()
    }
  }
})
export default class WalletOnEth extends Vue {
  wallet!: string
  tokens!: { address: string, symbol: string }[]
  msg!: MessageApi

  created () {
    if (!this.$eth.isMetaMask()) {
      return
    }
    this.msg = useMessage()
    this.$emit('networkChange', [this.isRight])
    this.startRun()
  }

  get isRight () {
    return (parseInt(this.$eth.tId) === parseInt(this.$eth.chainId))
  }

  async fetchBalance (): Promise<void> {
    if (!this.wallet) {
      return
    }
    const balance = await this.$eth.provider.getBalance(this.wallet)
    this.$store.commit('setBalance', {
      address: this.wallet,
      network: 'ethereum',
      symbol: 'ETH',
      amount: '0x' + balance.toString(16)
    })
    this.tokens.forEach(async (item) => {
      const token = this.$eth.getToken(item.address)
      const balance = await token.balance(this.wallet)
      this.$store.commit('setBalance', {
        address: this.wallet,
        network: 'ethereum',
        symbol: item.symbol,
        amount: '0x' + balance.toString(16)
      })
    })
  }

  msgWrongNetwork () {
    this.msg.create('Please connect to the appropriate Ethereum network.', {
      render: (props) => {
        return h(
          NAlert,
          {
            closable: true,
            type: 'error',
            title: 'Wrong network'
          },
          {
            default: () => props.content
          }
        )
      },
      duration: 0
    })
  }

  // this.$eth.provider.on('block', this.fetchBalance)
  async startRun (): Promise<void> {
    this.fetchBalance()

    // chain change event
    this.$eth.eth.on('chainChanged', async (chainId: string) => {
      const isRight = (parseInt(this.$eth.tId) === parseInt(chainId))
      this.$emit('networkChange', [isRight])
      console.log('changed')
      if (isRight) {
        this.msg.destroyAll()

        // create provider & turn on block event
        await this.$eth.initProvider()
        this.$eth.provider.on('block', this.fetchBalance)

        this.fetchBalance()
      } else {
        this.$store.commit('delBalance', {
          address: this.wallet,
          network: 'ethereum'
        })

        // turn off block event
        this.$eth.provider && this.$eth.provider.off('block')

        this.msgWrongNetwork()
      }
    })

    // account change event
    this.$eth.eth.on('accountsChanged', (accounts: string[]) => {
      const addr = accounts[0]
      this.$store.commit('setAddress', { key: 'ethereum', addr })
      const checksumed = getAddress(addr)
      const t = checksumed.slice(0, 6) + '⋯' + checksumed.slice(-6)
      if (!!this.wallet && this.wallet.toLowerCase() !== addr.toLowerCase()) {
        this.msg.success(`Connected to ${t}`, {
          render: (props) => {
            return h(
              NAlert,
              {
                closable: true,
                type: 'success',
                title: 'Address Changed'
              },
              {
                default: () => props.content
              }
            )
          },
          duration: 2000
        })
      }
    })
    if (this.isRight) {
      this.$eth.provider.on('block', this.fetchBalance)
    }
  }
}
