
import { Options, Vue } from 'vue-class-component'
@Options({
  props: {
    wallet: String,
    tokens: Array
  },
  watch: {
    wallet (v: string, o: string) {
      if (v && !o) {
        this.startRun()
      }

      if (v !== o && !!v && !!o) {
        this.fetchBalance()
      }
    }
  }
})
export default class WalletOnVet extends Vue {
  wallet!: string
  tokens!: {address: string, symbol: string}[]

  async fetchBalance (): Promise<void> {
    if (!this.wallet) {
      return
    }
    const account = await this.$vet.connex.thor.account(this.wallet).get()
    this.$store.commit('setBalance', {
      address: this.wallet,
      network: 'vechain',
      symbol: 'VET',
      amount: account.balance
    })
    const amounts = await this.$vet.fetchBalance(this.wallet, this.tokens.map(i => i.address))
    this.tokens.forEach((token, i) => {
      this.$store.commit('setBalance', {
        address: this.wallet,
        network: 'vechain',
        symbol: token.symbol,
        amount: amounts[i]
      })
    })
  }

  getBest ():void {
    const best = this.$vet.connex.thor.status.head.number
    this.$store.commit('setBestNum', { num: best })
  }

  created () {
    this.startRun()
  }

  async startRun (): Promise<void> {
    const tick = this.$vet.connex.thor.ticker()
    for (;;) {
      this.getBest()
      await this.fetchBalance()
      await tick.next()
    }
  }
}
